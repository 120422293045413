<template>
  <div class="box">
    <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="onSelect"
        />
        <div class="hint" v-if="showAddressList">暂无收货地址</div>
  </div>
</template>

<script>
export default {
    data() {
        return {
        checked: false,
        chosenAddressId: '2',
        list: [],
        nowId:'',
        showAddressList:false
        };
    },
    updated(){
        if(this.list.length == 0){
            this.showAddressList = true
        }else{
            this.showAddressList = false
        }
    },
    methods: {
        // 选择框跟随默认
        chosesBox(id){
            if(this.list)
            this.chosenAddressId = id
        },
        onAdd() {
            this.$emit('ok')
        },
        onEdit(item, index) {
            this.nowId = item.id
            this.$parent.ok(item)
            this.$parent.showDelete = true
            // this.$parent.AddressCode(item)
        },
        onSelect(item,index){
            this.$parent.addressList = item
        },
  },
}
</script>

<style lang="less" scoped>
    .box{
        .delivery_card{
            width: 90%;
            margin: 10px auto;
            li{
                display: flex;
                align-items: center;
                width: 100%;
                padding-bottom: 10px;
                border-bottom: 1px solid #ccc;
                position: relative;
                .userName{
                    margin-left: 5px;
                    width: 80%;
                    div{
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        .name{
                        font-size: 18px;
                        font-weight: 500;
                    }
                    .tel{
                        font-size: 14px;
                        color: #ccc;
                        margin: 0 5px;
                    }
                    .default{
                        text-align: center;
                        width: 30px;
                        height: 15px;
                        line-height: 15px;
                        background-color: red;
                        border-radius: 5px;
                        color: #fff;
                    }
                    }
                    .address{
                    margin-top: 10px;
                    font-size: 14px;
                    }
                }
                .user_edit{
                    width: 30px;
                    font-size: 20px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    margin-top: -10px;
                }
                
            }
            li:last-child{
                padding-bottom: 60px;
                border-bottom: none;
                .user_edit{
                    margin-top: -40px;
                }
            }
        }
            button{
                color: #fff;
                width: 307px;
                height: 43px;
                border-radius: 21px;
                background: #1E6FFF;
                border: 1px solid #1E6FFF;
                position: fixed;
                bottom: 0;
                left: 50%;
                margin-left: -153.5px;
            }
        
        .van-address-list{
            min-width: 350px;
            width: 100%;
        }
        .hint{
            font-size: 18px;
            color: #ccc;
            text-align: center;
        }
    }
</style>