<template>
<div class="selfDispath">
    <div :class="show?'modal':''" @click="showModal"></div>
    <div class="self_title">
        <span class="left" @click="go_back"><van-icon name="arrow-left" /></span>
        <div class=""></div>
        自提发货
    </div>
    <div class="self_content" v-if="!isAddress">
        <div class="self_tellid">
            <img src="@/assets/images/selfDispath/手机卡.png" alt="">
            <p>联通流量卡</p>
            <p>￥0/张</p>
        </div>
        <div class="number">
            <p>提货数量</p>
            <van-stepper v-model="value"  max="100" />
        </div>
        <div class="locat" @click="address">
            <img src="@/assets/images/selfDispath/位置.png" alt="">
            <div class="myAddress" v-if="!showAddressList">
                <p>{{addressList.address}}</p>
                <p>{{addressList.name}} <span>{{addressList.tel}}</span></p>
            </div>
            <p v-if="showAddressList">暂无收货地址</p>
            <img src="@/assets/images/selfDispath/箭头.png" alt="" @click="address">
            
            <!-- <van-popup v-model="show" closeable position="bottom" :lazy-render='false' :style="{ height: '60%' }">
                
            </van-popup> -->
        </div>
        <div class="send">
            <p>配送服务</p>
            <p>快递 <span>￥0</span></p>
        </div>
        <div class="price">
            <p class="price_detail">价格明细</p>
            <div class="price_num">
                <p>卡片{{value}}张</p>
                <p>￥{{value*0}}</p>
            </div>
            <div class="price_money">
                <p>快递</p>
                <p>￥0</p>
            </div>
        </div>
        <div class="box">
            <div class="payWay">
            <van-radio-group v-model="radio">
                <div class="zfb">
                    <div class="zfb_ico">
                        <img src="@/assets/images/selfDispath/支付宝.png" alt="">
                        <p>支付宝支付</p>
                    </div>
                    <van-radio name="1" label-position="left" checked-color="#FEA606">
                    </van-radio>
                </div>
                <!-- <div class="wx">
                    <div class="wx_ico">
                        <img src="@/assets/images/selfDispath/微信.png" alt="">
                        <p>微信支付</p>
                    </div>
                    <van-radio name="5" label-position="left" checked-color="#FEA606">
                    </van-radio>
                </div> -->
            </van-radio-group>
        </div>
        </div>
    </div>
    <div class="self_add" v-if="isAddress">
        <van-address-edit
        :address-info="AddressInfo"
        :area-list="areaList"
        show-set-default
        :show-delete="showDelete ? true : false"
        :area-columns-placeholder="['请选择', '请选择', '请选择']"
        @save="onSave"
        @delete="onDelete"      
        />
        
    </div>
    <div class="submit" v-if="!isAddress">
        <button @click="buyCard">提交订单</button>
        <p><span style="font-size:12px">￥</span>{{0*value}}</p>
        <p>共{{value}}张</p>
    </div>
    <div class="popup" v-show="show">
        <div class="close" @click="close"><van-icon name="cross" /></div>
                <shippingAddress 
                @ok='ok' 
                ref="shippingAddress"
                ></shippingAddress>
            </div>
</div>
</template>

<script>
var userInfos = sessionStorage.getItem('UserInfo')
var option =JSON.parse(userInfos)
console.log(option,'用户登录信息');
import distubritionTwoApi from '@/untils/distubritionTwo.js'//api接口
import { areaList } from '@vant/area-data';
import shippingAddress from './components/shippingAddress.vue'//地址列表组件
export default {
    components:{
        shippingAddress,
    },
    mounted(){
        this.getaddressList()
    },
    data(){
        return{
            value:1,
            radio: '1',
            show: false,//弹出层
            isAddress:false,//是否显示添加
            areaList,
            addressList:{},//地址
            province:'',
            city:'',
            county:'',
            provinceCode:'',
            cityCode:'',
            areaCode:'',
            showDelete:false,//删除按钮的显示与隐藏
            showAddressList:false,//是否显示暂无地址
        };
    },
    methods:{
        getaddressList(){
            let data = {
                user_id:option.id
            }
            let arr = []
            distubritionTwoApi.addressList({data}).then((res)=>{
                for(let i = 0; i < res.data.data.data.length; i++){
                    arr.push({
                        id:res.data.data.data[i].id,
                        name:res.data.data.data[i].nickname,
                        tel:res.data.data.data[i].mobile,
                        address:res.data.data.data[i].address_info.replace(/,/g,""),
                        isDefault:res.data.data.data[i].default?true:false,
                        area:res.data.data.data[i].area,
                        city:res.data.data.data[i].city,
                        province:res.data.data.data[i].province ,
                        info:res.data.data.data[i].info

                    })
                }
                this.$refs.shippingAddress.list = arr
                this.defaultAddress()

            })
        },
        // 点击空白，遮罩层关闭
        showModal(){
            this.show=false
        },
        // 选择地址的关闭按钮
        close(){
            this.show = false
        },
        // 创建自提订单
        buyCard(){
            let data = {
                mobile:this.addressList.tel,
                name:this.addressList.name,
                province:this.addressList.province,
                city:this.addressList.city,
                area:this.addressList.area,
                info:this.addressList.info,
                number:this.value,
                pay_type:this.radio,
                user_id:option.id,
                openid:''
            }
            distubritionTwoApi.buyCard({data}).then((res)=>{
                if(res.data.code == 200){
                    this.$toast('提交订单成功')
                    // window.location.href = res.data.data.data.data.expend.pay_info
                    this.$router.push({
                        path:'/cancelCard',
                        query: {
                            active: 1,
                            },
                    })
                }
            })
        }, 
        //返回
        go_back(){
            if(this.isAddress){
                this.isAddress =false
            }else{
                this.$router.go(-1)
            }
        },
        // 显示当前默认的地址
        defaultAddress(){
            // 如果地址列表有数据
            if(this.$refs.shippingAddress.list.length > 0){
                // 判断地址列表有没有默认的，如果有默认的将数据显示默认的，将默认前的单选框选上
                if(this.$refs.shippingAddress.list.filter((item)=> item.isDefault ==true)[0]){
                    this.addressList = this.$refs.shippingAddress.list.filter((item)=> item.isDefault ==true)[0]
                    let choseId = this.addressList.id
                    this.$refs.shippingAddress.chosesBox(choseId)
                    this.showAddressList = false
                }else{
                    // 如果没有默认的，将地址列表第一项设为默认
                    this.addressList = this.$refs.shippingAddress.list[0]
                    this.$refs.shippingAddress.list[0].isDefault = true
                    this.$refs.shippingAddress.chosesBox(this.$refs.shippingAddress.list[0].id)
                    this.showAddressList = false
                }
            // 如果地址列表没有数据，展示为空
            }else{
                this.addressList = {}
                this.showAddressList = true
            }
            
        },
        // 子组件调用父组件的函数，点击编辑将数据传过来
        ok(value){
            this.isAddress=true
            this.show = false
             this.showDelete = false
            //说明从编辑来的
            if(value){
                this.showDelete = true
                // 数据回显
                this.AddressInfo={
                    name:value.name,
                    tel:value.tel,
                    city:value.city,
                    province:value.province,
                    city:value.city,
                    county:value.area,
                    addressDetail:value.address,
                    isDefault:value.isDefault,
                    addressDetail:value.info
                }
                this.AddressCode(this.AddressInfo)
                this.AddressInfo.provinceCode=this.provinceCode

                this.AddressInfo.cityCode=this.cityCode
                this.AddressInfo.areaCode = this.areaCode
            //说明是添加新的收货地址，将表单置空 
            }else{
                this.AddressInfo={}
            }
        },
        // 获取省市区Code号
        AddressCode(item){
            this.defaultProvince = item.province;//河南省
            this.defaultCity = item.city;//郑州市
            this.defaultArea = item.county;//中原区
            // 省
            let resultProvince = Object.entries (areaList.province_list);
            resultProvince.forEach((item) => {
                if (item[1] == this.defaultProvince) {
                this.provinceCode = item[0];
                }
            });
            // 市
            let resultCity = Object.entries(areaList.city_list);
            resultCity.forEach((item) => {
                if (item[1] == this.defaultCity) {
                this.cityCode = item[0];
                }
            });
            // 区
            let resultArea = Object.entries(areaList.county_list);
            resultArea.forEach((item) => {
                if (item[1] == this.defaultArea) {
                this.areaCode = item[0];
                }
            });
        },
        // 点击收货地址,显示收货地址列表弹窗
        address(){
            this.show=true
        },
        onSave(content) {
            // 如果显示删除按钮,代表是编辑
            if(this.showDelete){
                this.isAddress = false
                let data = {
                    mobile:content.tel,
                    nickname:content.name,
                    province:content.province,
                    city:content.city,
                    area:content.county,
                    info:content.addressDetail,
                    uuid:option.uuid,
                    default:content.isDefault?1:0,
                    address_id:this.$refs.shippingAddress.nowId
                }

                distubritionTwoApi.saveAddress({data}).then((res)=>{
                    if(res.data.code == 200){
                        this.$toast(res.data.msg)
                    }else{
                        this.$toast(res.data.msg)
                    }
                    
                    this.getaddressList()
                })
            }else{
                this.isAddress = false
                let data = {
                    mobile:content.tel,
                    nickname:content.name,
                    province:content.province,
                    city:content.city,
                    area:content.county,
                    info:content.addressDetail,
                    uuid:option.uuid,
                    default:content.isDefault?1:0,
                }
                distubritionTwoApi.addAddress({data}).then((res)=>{
                    if(res.data.code == 200){
                        this.$toast(res.data.msg)
                        this.getaddressList()
                    }
                    
                })
            }
            if(content.isDefault){
                let allDefault = this.$refs.shippingAddress.list.filter((item)=> item.isDefault ==true)
                allDefault[0].isDefault = false
                this.defaultAddress()

            }
        },
        onDelete(content) {
            let data = {
                uuid:option.uuid,
                address_id:this.$refs.shippingAddress.nowId
            }
            distubritionTwoApi.deleteAddress({data}).then((res)=>{
                if(res.data.code == 200){
                    this.$toast('删除成功')
                    this.getaddressList()
                    this.go_back()
                }
            })
            
        },
    }
}
</script>
<style lang="less" scoped>
.selfDispath{
    min-height: 100vh;
    .self_title{
      width: 100%;
      height: 44px;
      line-height: 44px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
      text-align: center;
      font-size: 16px;
      color: #333;
      font-weight: 550;
      .left{
        float: left;
        margin-left: 20px;
      }
    }
    .self_content{
        margin: 0 auto;
        .self_tellid{
            width: 350px;
            height: 41px;
            line-height: 41px;
            display: flex;
            justify-content: space-between;
            margin: 19px auto;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 8px;
            img{
                margin-left: 11px;
            }
            p:nth-child(2){
                font-size: 18px;
                margin-right: 100px;
            }
            p:nth-child(3){
                color: #666666;
                font-size: 14px;
                margin-right: 18px;
            }
        }
        .number{
            margin: 0 auto;
            background-color: #fff;
            height: 58px;
            width: 350px;
            line-height: 58px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 8px;
            p{
                padding-left: 17px;
            }
            .van-stepper{
                padding-right: 18px;
            }
        }
        .locat{
            margin: 14px auto;
            width: 350px;
            height: 65px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            // box-sizing: border-box;
            border-radius: 8px;
            img:nth-child(1){
                margin-left: 20px;
            }
            img:nth-child(3){
                margin-right: 25px;
            }
            .myAddress{
                margin-right: 10px;
                width: 250px;
                overflow:hidden;
                p{
                    font-weight: 400;
                    overflow:hidden;
                    text-overflow:ellipsis;
                    display:-webkit-box;
                    -webkit-box-orient:vertical;
                    -webkit-line-clamp:2; 
                }
                p:nth-child(1){
                    font-size: 13px;
                    line-height: 16px;
                }
                p:nth-child(2){
                    color: #999999;
                    font-size: 12px;
                    margin-top: 3px;
                }
            }
        }
        .send{
            margin: 0 auto;
            background-color: #fff;
            height: 58px;
            width: 350px;
            line-height: 58px;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 8px;
            p:nth-child(1){
                margin-left: 17px;
            }
            p:nth-child(2){
                margin-right: 18px;
                span{
                    color: red;
                }
            }
        }
        .price{
            width: 350px;
            height: 122px;
            background-color: #fff;
            margin: 14px auto; 
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 8px;
            .price_detail{
                font-size: 14px;
                color: #666666;
                line-height: 13px;
                margin-left: 20px;
                margin-top: 22px;
            }
            .price_num{
                margin-top: 15px;
                margin-left: 20px;
                display: flex;
                justify-content: space-between;
                p:nth-child(1){
                    font-size: 14px;
                    color: #333333;
                    line-height: 13px;
                }
                p:nth-child(2){
                    font-size: 14px;
                    color: #FB5152;
                    line-height: 13px;
                    margin-right: 18px;
                }
            }
            .price_money{
                margin-top: 13px;
                margin-left: 20px;
                display: flex;
                justify-content: space-between;
                p:nth-child(1){
                    font-size: 14px;
                    line-height: 13px;
                }
                p:nth-child(2){
                    font-size: 14px;
                    color: #FB5152;
                    line-height: 13px;
                    margin-right: 18px;

                }
            }
        }
        .box{
            height: 200px;
            margin: 14 auto;
            padding-bottom: 91px;
            .payWay{
            width: 350px;
            margin: 0 auto;
            background-color: #fff;
            overflow: hidden;
            box-sizing: border-box;
            border-radius: 8px;
            .van-radio-group{
                
                .zfb{
                    margin: 20px 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .zfb_ico{
                        display: flex;
                        margin-left: 23px;
                        align-items: center;
                    }
                        p{
                            margin-left: 17px;
                            font-size: 14px;
                        }
                    
                    .van-radio{
                        margin-right: 19px;
                    }
                }
                .wx{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    .wx_ico{
                        display: flex;
                        margin-left: 23px;
                        align-items: center;
                        p{
                            margin-left: 17px;
                            font-size: 14px;
                        }
                    }
                    .van-radio{
                        margin-right: 19px;
                    }
                }
            }            
        }
        }
    }
    .submit{
            width: 375px;
            height: 92px;
            background-color: #fff;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            margin: 0 auto;
            position: fixed;
            bottom: 0;
            left: 50%;
            margin-left: -187.5px;            
            button{
                width: 121px;
                height: 43px;
                border-radius: 21px;
                background: url('~@/assets/images/selfDispath/按钮.png')no-repeat;
                background-size: 100% 100%;
                margin-right: 17px;
                font-size: 18px;
                color: #fff;
                font-weight: 500;
            }
            p:nth-child(2){
                font-size: 18px;
                font-weight: 400;
                color: #F95150;
                margin: 0 15px;
            }
            p:nth-child(3){
                font-size: 12px;
                font-weight: 400;
                color: #999999;
            }
    }
    .popup{
        width: 100%;
        height: 50%;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        margin: 0 auto;
        overflow-y: auto;
        z-index: 99999;
        // position: relative;
        .close{
            font-size: 20px;
            position: absolute;
            top: 0;
            right: 0;
            margin: 10px;
            color: #ccc;
            cursor: pointer;
        }
    }
    .modal{
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }
}
</style>